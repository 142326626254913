import React from "react";
import styled from "styled-components";

const Button = styled.button<{ size: "big" | "small" }>(({ size }) => ({
  background: "none",
  border: "none",
  padding: 0,
  margin: 0,
  cursor: "pointer",
  transition: "0.125s",
  "&:hover": {
    margin: -2,
  },
  "& img": {
    transition: "0.125s",
    position: "relative",
    left: 2,
    width: size === "big" ? 22 : 16,
    height: size === "big" ? 22 : 16,
    filter: "drop-shadow(0 0 3px rgba(0, 0, 0, 0.4))",
    fill: "#fff",
  },
  "&:hover img": {
    width: size === "big" ? 26 : 20,
    height: size === "big" ? 26 : 20,
  },
}));

export const SvgButton = ({
  src,
  alt,
  onClick,
  size = "big",
  className = "",
}: {
  src: string;
  alt: string;
  onClick: any;
  size?: "big" | "small";
  className?: string;
}) => (
  <Button onClick={onClick} size={size} className={className}>
    <img
      src={process.env.PUBLIC_URL + "/assets/" + src}
      width={22}
      height={22}
      alt={alt}
    />
  </Button>
);

import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useMediaQuery } from "react-responsive";
import { useSwipeable } from "react-swipeable";

import ReactPlayer from "react-player";
import { SvgButton } from "./svg-button";
import { PerspectiveButton } from "./perspective-button";
import { CurrentlyPlaying } from "./currently-playing";

type video = { url: string; name: string; image: string };
export const red = "#EE4242";
export const red75 = "rgba(238, 66, 66, 0.75)";
export const white = "#fff";
export const textGray = "#B7B7B7";
export const bgGray = "#222222";

export const mobileLandscapeQuery = "@media (max-width: 1199px)";
export const mobilePortraitQuery = "@media (orientation: portrait)";

const Landing = styled.div({
  position: "fixed",
  height: "100vh",
  width: "100vw",
  zIndex: 999,
  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/landing.jpg)`,
  backgroundSize: "cover",
  display: "grid",
  gridTemplateColumns: "1fr auto 4fr",
  gridTemplateRows: "1fr auto 1fr",
  overflowY: "auto",
});

const LandingTextContainer = styled.div({
  gridColumn: "2 / 3",
  gridRow: "2 / 3",
  maxWidth: 725,
  margin: "0 25px",
  [mobileLandscapeQuery]: {
    maxWidth: 400,
  },
});

const LandingTextHeadline = styled.h1({
  fontSize: 70,
  color: white,
  font: "normal normal normal 70px/65px Raleway",
  marginBottom: 0,
  [mobileLandscapeQuery]: {
    font: "normal normal normal 35px/30px Raleway",
  },
});

const LandingTextParagraph = styled.p({
  font: "normal normal normal 25px/40px Raleway",
  color: textGray,
  [mobileLandscapeQuery]: {
    font: "normal normal normal 15px/20px Raleway",
  },
});

const SemiBoldHeadlinePart = styled.span({
  font: "normal normal 600 70px/65px Raleway",
  [mobileLandscapeQuery]: {
    font: "normal normal 600 35px/30px Raleway",
  },
  display: "block",
});

const LandingPlayContainer = styled.div({
  gridColumn: "3 / 4",
  gridRow: "2 / 3",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const LandingPlayButtonContainer = styled.div({
  position: "relative",
  width: 260,
  height: 260,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 auto",
  [mobileLandscapeQuery]: {
    width: 144,
    height: 144,
  },
});

const landingPlayButtonBackgroundAnimation = keyframes`
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1.0);
  }
`;

const LandingPlayButtonBackground = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  animation: ${landingPlayButtonBackgroundAnimation} 1s ease-out infinite;
  animation-direction: alternate;
`;

const LandingPlayButton = styled.img({
  position: "relative",
  left: 10,
  cursor: "pointer",
  width: 83,
  height: 90,
  transition: "0.3s",
  "&:hover": {
    width: 83 * 1.2,
    height: 90 * 1.2,
  },
  [mobileLandscapeQuery]: {
    left: 7,
    width: 54,
    height: 61,
    "&:hover": {
      width: 54 * 1.2,
      height: 61 * 1.2,
    },
  },
});

const Wrapper = styled.div({
  width: "100vw",
  height: "100vh",
  zIndex: 0,
  position: "relative",
  background: bgGray,
});

const TopSection = styled.div({
  [mobilePortraitQuery]: {
    padding: 25,
    display: "grid",
    gridTemplateColumns: "1fr auto",
  },
});

const MidSection = styled.div({
  [mobilePortraitQuery]: {
    position: "relative",
  },
});

const Controls = styled.div({
  position: "absolute",
  top: 45,
  right: 80,
  display: "grid",
  gridTemplateColumns: "auto auto auto auto",
  gridColumnGap: 30,
  gridRowGap: 30,
  [mobileLandscapeQuery]: {
    top: 20,
    right: 37,
    gridColumnGap: 20,
    gridRowGap: 20,
  },
  [mobilePortraitQuery]: {
    top: "auto",
    right: 80,
    bottom: 15,
    left: 80,
    gridTemplateColumns: "1fr auto auto auto",
    [mobileLandscapeQuery]: {
      right: 15,
      left: 15,
    },
  },
});

const SettingsButton = styled(SvgButton)({
  gridColumn: "1 / 2",
  [mobileLandscapeQuery]: {
    gridColumn: "2 / 3",
  },
  [mobilePortraitQuery]: {
    gridColumn: "2 / 3",
    [mobileLandscapeQuery]: {
      gridColumn: "3 / 4",
    },
  },
});

const VolumeControls = styled.div({
  display: "grid",
  gridTemplateColumns: "auto 70px",
  gridColumnGap: 14,
  gridColumn: "2 / 3",
  [mobilePortraitQuery]: {
    gridColumn: "3 / 4",
  },
});

const FullscreenButton = styled(SvgButton)({
  gridColumn: "3 / 4",
  [mobilePortraitQuery]: {
    gridColumn: "4 / 5",
  },
});

export const RedBackgroundText = styled.span({
  background: red75,
  color: white,
  padding: 10,
  fontWeight: 700,
  fontSize: 25,
  textTransform: "uppercase",
  display: "grid",
  gridTemplateColumns: "auto auto",
  gridColumnGap: 10,
  [mobileLandscapeQuery]: {
    fontSize: 14,
    gridColumnGap: 5,
    gridColumn: "4 / 5",
  },
});

const VideoControls = styled.div<{ isShowing: boolean }>(({ isShowing }) => ({
  display: "grid",
  gridTemplateColumns: isShowing ? "auto 1fr 60px" : "auto",
  gridColumnGap: 30,
  gridColumn: "1 / 5",
  [mobilePortraitQuery]: {
    gridColumn: "1 / 2",
    gridRow: "1 / 2",
    [mobileLandscapeQuery]: {
      gridColumn: "1 / 3",
    },
  },
}));

const LogoContainer = styled.div({
  position: "absolute",
  top: 34,
  left: 80,
  [mobileLandscapeQuery]: {
    top: 20,
    left: 37,
  },
  [mobilePortraitQuery]: {
    position: "relative",
    top: 0,
    left: 0,
  },
});
const Logo = styled.img({
  width: 180,
  [mobileLandscapeQuery]: {
    width: 96,
  },
});

export const Center = styled.div({
  display: "flex",
  alignItems: "center",
  justifyItems: "center",
});
const Player = styled(ReactPlayer)({
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: -2,
  [mobilePortraitQuery]: {
    position: "relative",
  },
});
const VideoText = styled.span({
  color: "#fff",
  textShadow: "rgba(0,0,0,0.3) 0px 0px 6px",
  fontSize: 24,
  [mobileLandscapeQuery]: {
    fontSize: 16,
  },
});

const Views = styled.div<{ isOpen: boolean }>(({ isOpen }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  boxSizing: "content-box",
  height: isOpen ? 250 : 50,
  padding: "30px 80px 30px 80px",
  paddingTop: isOpen ? 0 : 20,
  transition: "0.3s",
  overflowY: "hidden",
  [mobileLandscapeQuery]: {
    height: isOpen ? 120 : 10,
    paddingLeft: 37,
    paddingRight: 37,
  },
  [mobilePortraitQuery]: {
    position: "relative",
    boxSizing: "border-box",
    height: "auto",
    paddingTop: 20,
  },
}));

const ViewsHeadline = styled.span({
  color: "#fff",
  textShadow: "rgba(0,0,0,0.3) 0px 0px 6px",
  cursor: "pointer",
  fontSize: 35,
  fontWeight: 700,
  marginBottom: 15,
  display: "block",
  [mobileLandscapeQuery]: {
    fontSize: 18,
  },
  [mobilePortraitQuery]: {
    cursor: "auto",
  },
});

const VideoList = styled.div<{ isOpen: boolean }>(({ isOpen }) => ({
  opacity: isOpen ? 1 : 0,
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
  gridColumnGap: 16,
  rowGap: 16,
  gridTemplateRows: "1fr",
  transition: "0.125s",
  [mobilePortraitQuery]: {
    gridTemplateColumns: "1fr 1fr",
  },
}));

const ViewsReminder = styled.div<{
  isVisible: boolean;
  isViewsDrawerOpen: boolean;
}>(({ isVisible, isViewsDrawerOpen }) => ({
  opacity: isVisible ? 1 : 0,
  transition: "0.5s",
  textAlign: "center",
  position: "absolute",
  bottom: isViewsDrawerOpen ? "350px" : "25%",
  left: 0,
  right: 0,
  zIndex: -1,
  [mobileLandscapeQuery]: {
    bottom: isViewsDrawerOpen ? "200px" : "25%",
  },
  [mobilePortraitQuery]: {
    bottom: 70,
  },
}));

const Uppercase = styled.span({
  textTransform: "uppercase",
});

let reminderTimer = setTimeout(() => {}, 0);
const reminderTime = 180_000;

let forceReminderTimer = setTimeout(() => {}, 0);
const forceReminderTime = 64_000;

const queryString = window.location.search;

function App() {
  const isMobileLandscape = useMediaQuery({ query: "(max-width: 1199px)" });
  const isMobilePortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const [isPlaying, setIsPlaying] = useState(false);
  const videos: video[] = [
    {
      url: "videos/Emi/video/dashfile.mpd",
      name: "Outside cam",
      image: "outside.jpg",
    },
    {
      url: "videos/Jasmine/video/dashfile.mpd",
      name: "Jasmine cam",
      image: "jasmine.jpg",
    },
    {
      url: "videos/Anton/video/dashfile.mpd",
      name: "Anton cam",
      image: "anton.jpg",
    },
    {
      url: "videos/Rodrigo/video/dashfile.mpd",
      name: "Rodrigo cam",
      image: "rodrigo.jpg",
    },
    {
      url: "videos/Oriane/video/dashfile.mpd",
      name: "Oriane cam",
      image: "oriane.jpg",
    },
    {
      url: "videos/Annu/video/dashfile.mpd",
      name: "Annu cam",
      image: "annu.jpg",
    },
  ];
  const resetReminderTimeout = () => {
    clearTimeout(reminderTimer);
    reminderTimer = setTimeout(() => {
      setShouldShowViewsReminder(true);
      setTimeout(() => {
        setShouldShowViewsReminder(false);
        resetReminderTimeout();
      }, 5_000);
    }, reminderTime);
  };
  const [currentVideo, setCurrentVideo] = useState(videos[0]);
  const switchVideo = (video: video) => {
    if (player!.getCurrentTime() > 2) {
      setPlayed(player!.getCurrentTime());
    }
    setCurrentVideo(video);
    setShouldShowViewsReminder(false);
    setIsViewsDrawerOpen(false);
    resetReminderTimeout();
    setHasSwitchedViews(true);
  };
  const [hasSwitchedViews, setHasSwitchedViews] = useState(false);
  const [played, setPlayed] = useState(0);
  const [visualPlayed, setVisualPlayed] = useState(played);
  const [player, setPlayer] = useState<ReactPlayer | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(
    Boolean(document.fullscreenElement)
  );
  const [volume, setVolume] = useState(1.0);
  const [isMuted, setIsMuted] = useState(false);
  const [volumeBeforeMute, setVolumeBeforeMute] = useState(volume);

  // Interface
  const [isViewsDrawerOpen, setIsViewsDrawerOpen] = useState(false);
  const [shouldShowViewsReminder, setShouldShowViewsReminder] = useState(false);
  const [isShowingVideoControls, setIsShowingVideoControls] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [isFirstPlay, setIsFirstPlay] = useState(true);

  const mainSwipeHandlers = useSwipeable({
    onSwipedUp: () => {
      setIsViewsDrawerOpen(true);
    },
    onSwipedDown: () => {
      setIsViewsDrawerOpen(false);
    },
  });

  const viewsDrawerSwipeHandlers = useSwipeable({
    onSwipedUp: () => {
      setIsViewsDrawerOpen(true);
    },
    onSwipedDown: () => {
      setIsViewsDrawerOpen(false);
    },
  });

  const forceViewSwitch = () => {
    if (hasSwitchedViews === false) {
      clearTimeout(reminderTimer);
      setIsViewsDrawerOpen(true);
      setShouldShowViewsReminder(true);
    }
  };

  useEffect(() => {
    if (!isFirstPlay) {
      if (hasSwitchedViews) {
        clearTimeout(forceReminderTimer);
      } else {
        forceReminderTimer = setTimeout(() => {
          forceViewSwitch();
        }, forceReminderTime);
      }
    }
  }, [hasSwitchedViews, isFirstPlay]);

  const pause = () => {
    if (player!.getCurrentTime() > 2) {
      setPlayed(player!.getCurrentTime());
    }
    setIsPlaying(false);
    if (isFullscreen) {
      document
        .exitFullscreen()
        .then(() => setIsFullscreen(false))
        .catch(() => {
          setIsFullscreen(false);
        });
    }
  };

  const play = () => {
    setIsPlaying(true);
    if (isFirstPlay) {
      resetReminderTimeout();
      setIsFirstPlay(false);
      if (hasSwitchedViews) {
        clearTimeout(forceReminderTimer);
      } else {
        forceReminderTimer = setTimeout(() => {
          forceViewSwitch();
        }, forceReminderTime);
      }
    }
    if (!isFullscreen) {
      container!.requestFullscreen().then(() => setIsFullscreen(true));
    }
  };

  const ref = (player: ReactPlayer) => {
    setPlayer(player);
  };

  const containerRef = (container: HTMLDivElement) => {
    setContainer(container);
  };

  return (
    <Wrapper className="App" ref={containerRef}>
      {isFirstPlay && (
        <Landing>
          <LandingTextContainer>
            <LandingTextHeadline>
              <SemiBoldHeadlinePart>An Action for</SemiBoldHeadlinePart>
              Norrtälje Town Hall
            </LandingTextHeadline>
            <LandingTextParagraph>
              This is a streamed performance where you can switch between which
              performer to follow. You will be able to change perspective
              whenever you want, as often as you want! Discover different
              pathways and fun details between 6 cameras!
            </LandingTextParagraph>
            <LandingTextParagraph>
              Press play and join the adventure!
              <br />
              <span style={{ fontSize: "80%" }}>
                We're experiences issues with iPhones and Safari.
              </span>
            </LandingTextParagraph>
            <LandingPlayButtonContainer>
              <LandingPlayButtonBackground
                src={
                  process.env.PUBLIC_URL + "/assets/play-button-background.svg"
                }
              />
              <LandingPlayButton
                src={process.env.PUBLIC_URL + "/assets/play.svg"}
                onClick={() => {
                  play();
                }}
              />
            </LandingPlayButtonContainer>
          </LandingTextContainer>
          <LandingPlayContainer></LandingPlayContainer>
        </Landing>
      )}

      <div
        onClick={() => {
          setIsViewsDrawerOpen(!isViewsDrawerOpen);
        }}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1,
        }}
        {...mainSwipeHandlers}
      />

      <TopSection>
        <LogoContainer>
          <a href="https://reactactions.com/" style={{ border: "none" }}>
            <Logo
              src={process.env.PUBLIC_URL + "/assets/logo.svg"}
              width={180}
              alt="ReAct! logo"
              style={{
                filter: "drop-shadow(0 0 6px rgba(0, 0, 0, 0.4))",
              }}
            />
          </a>
        </LogoContainer>
        {isMobilePortrait && <CurrentlyPlaying title={currentVideo.name} />}
      </TopSection>

      <MidSection>
        <Player
          ref={ref}
          playing={isPlaying}
          url={currentVideo.url}
          onPlay={() => {
            player?.seekTo(played, "seconds");
          }}
          onSeek={(timePlayed: number) => {
            if (timePlayed > 2) {
              setPlayed(timePlayed);
            }
          }}
          onProgress={(timePlayed: any) => {
            if (!isSeeking) {
              if (timePlayed.playedSeconds > 2) {
                setPlayed(timePlayed.playedSeconds);
              }
            }
          }}
          onEnded={() => {
            pause();
          }}
          volume={volume}
          width="100%"
          height={isMobilePortrait ? "auto" : "100%"}
          muted={isMuted}
        />

        <Controls>
          <SettingsButton
            src="toggle-controls.svg"
            onClick={() => {
              setIsShowingVideoControls(!isShowingVideoControls);
            }}
            alt="Toggle video controls"
          />
          {!isMobileLandscape && (
            <VolumeControls>
              <SvgButton
                alt="Volume"
                onClick={() => {
                  if (!isMuted) {
                    setVolumeBeforeMute(volume);
                    setVolume(0);
                    setIsMuted(true);
                  } else {
                    setVolume(volumeBeforeMute);
                    setIsMuted(false);
                  }
                }}
                src={isMuted ? "volume-mute.svg" : "volume-playing.svg"}
              />
              <Center>
                <Slider
                  value={volume}
                  onChange={(value) => {
                    setVolume(value);
                    if (value === 0) {
                      setIsMuted(true);
                    } else {
                      setIsMuted(false);
                    }
                  }}
                  onBeforeChange={(value) => {
                    if (value > 0) {
                      setVolumeBeforeMute(value);
                    }
                  }}
                  min={0}
                  max={1}
                  step={0.01}
                  railStyle={{
                    border: "2px solid #ccc",
                    height: 0,
                    borderRadius: 2,
                  }}
                  trackStyle={{
                    border: "2px solid " + red,
                    height: 0,
                    borderRadius: 2,
                  }}
                  handleStyle={{ backgroundColor: red, border: "none" }}
                  style={{
                    filter: "drop-shadow(0 0 3px rgba(0, 0, 0, 0.4))",
                  }}
                />
              </Center>
            </VolumeControls>
          )}

          <FullscreenButton
            alt="Toggle fullscreen"
            onClick={() => {
              if (!document.fullscreenElement) {
                container!
                  .requestFullscreen()
                  .then(() => setIsFullscreen(true));
              } else {
                document
                  .exitFullscreen()
                  .then(() => setIsFullscreen(false))
                  .catch(() => {
                    setIsFullscreen(false);
                  });
              }
            }}
            src="fullscreen.svg"
          />

          {!isMobilePortrait && <CurrentlyPlaying title={currentVideo.name} />}

          <VideoControls isShowing={isShowingVideoControls}>
            {isShowingVideoControls && (
              <>
                <SvgButton
                  alt={isPlaying ? "Pause" : "Play"}
                  src={isPlaying ? "pause.svg" : "play.svg"}
                  onClick={() => {
                    if (isPlaying) {
                      pause();
                    } else {
                      play();
                    }
                  }}
                />
                <Center>
                  <Slider
                    value={played / player!.getDuration()}
                    onChange={(value) => {
                      setPlayed(value * player!.getDuration());
                    }}
                    onBeforeChange={() => {
                      setIsSeeking(true);
                    }}
                    onAfterChange={() => {
                      setIsSeeking(false);
                      player?.seekTo(played, "seconds");
                    }}
                    min={0}
                    max={1}
                    step={0.001}
                    railStyle={{
                      border: "2px solid #ccc",
                      height: 0,
                      borderRadius: 2,
                    }}
                    trackStyle={{
                      border: "2px solid " + red,
                      height: 0,
                      borderRadius: 2,
                    }}
                    handleStyle={{ backgroundColor: red, border: "none" }}
                    style={{
                      filter: "drop-shadow(0 0 3px rgba(0, 0, 0, 0.4))",
                    }}
                  />
                </Center>
                <Center style={{ justifyItems: "flex-end" }}>
                  <VideoText style={{ fontSize: 18 }}>
                    {Math.floor(played / 60)}:{played % 60 < 10 && "0"}
                    {Math.floor(played % 60)}
                  </VideoText>
                </Center>
              </>
            )}
          </VideoControls>
        </Controls>
        <ViewsReminder
          isVisible={shouldShowViewsReminder}
          isViewsDrawerOpen={isViewsDrawerOpen}
        >
          <VideoText style={{ position: "relative" }}>
            Remember to switch view
            <img
              src={process.env.PUBLIC_URL + "/assets/drawn-arrow-down.svg"}
              width={isMobileLandscape ? 40 : 67}
              height={isMobileLandscape ? 40 : 66}
              alt="Arrow down"
              style={{
                position: "absolute",
                bottom: isMobileLandscape ? -35 : -55,
                left: isMobileLandscape ? -40 : -67,
                filter: "drop-shadow(0 0 3px rgba(0, 0, 0, 0.4))",
              }}
            />
          </VideoText>
        </ViewsReminder>
      </MidSection>

      <Views
        isOpen={isViewsDrawerOpen}
        onMouseEnter={() => {
          setIsViewsDrawerOpen(true);
        }}
        onMouseLeave={() => {
          setIsViewsDrawerOpen(false);
        }}
        {...viewsDrawerSwipeHandlers}
      >
        <ViewsHeadline
          onClick={() => {
            setIsViewsDrawerOpen(!isViewsDrawerOpen);
          }}
        >
          <Uppercase>
            Switch view{" "}
            {!isMobilePortrait &&
              (isViewsDrawerOpen ? (
                <SvgButton
                  src="arrow-down.svg"
                  alt="Arrow down"
                  onClick={() => {}}
                  size={isMobileLandscape ? "small" : "big"}
                />
              ) : (
                <SvgButton
                  src="arrow-up.svg"
                  alt="Arrow up"
                  onClick={() => {}}
                  size={isMobileLandscape ? "small" : "big"}
                />
              ))}
          </Uppercase>
        </ViewsHeadline>

        <VideoList isOpen={isViewsDrawerOpen || isMobilePortrait}>
          {videos.map((video) => {
            if (video.name === currentVideo.name && !isMobilePortrait) {
              return null;
            }
            return (
              <PerspectiveButton
                src={video.image}
                onClick={() => {
                  switchVideo(video);
                }}
                name={video.name}
                key={video.name}
              />
            );
          })}
        </VideoList>
      </Views>
    </Wrapper>
  );
}

export default App;

import React from "react";
import styled from "styled-components";
import { RedBackgroundText, Center, red, red75 } from "./App";
import { Ellipse } from "./ellipse";
import { useMediaQuery } from "react-responsive";

const Button = styled.button<{ backgroundImage: string }>(
  ({ backgroundImage }) => ({
    background: "none",
    border: "none",
    padding: 0,
    margin: 0,
    cursor: "pointer",
    transition: "0.125s",
    backgroundSize: "cover",
    height: 210,
    position: "relative",
    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) ), ${backgroundImage}`,
    backgroundPosition: "center 20%",
    "&:hover": {
      backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), ${backgroundImage}`,
    },
    "& > img": {
      transition: "0.125s",
      width: 0,
      height: 0,
    },
    "&:hover > img": {
      width: 54,
      height: 39,
    },
    "@media (max-width: 1199px)": {
      height: 104,
    },
    "@media (orientation: portrait)": {
      height: 300,
      "@media (max-width: 1199px)": {
        height: 250,
      },
      "@media (max-width: 1000px)": {
        height: 200,
      },
      "@media (max-width: 800px)": {
        height: 150,
      },
      "@media (max-width: 600px)": {
        height: 97,
      },
    },
  })
);

export const PerspectiveButton = ({
  src,
  onClick,
  name,
}: {
  src: string;
  onClick: any;
  name: string;
}) => {
  const isMobileLandscape = useMediaQuery({ query: "(max-width: 1199px)" });
  return (
    <Button
      onClick={onClick}
      backgroundImage={`url("${process.env.PUBLIC_URL}/assets/${src}")`}
    >
      <img src={process.env.PUBLIC_URL + "/assets/view.svg"} alt="View" />
      <RedBackgroundText
        style={{
          position: "absolute",
          top: 9,
          right: 5,
          fontSize: isMobileLandscape ? 9 : 14,
          padding: isMobileLandscape ? 3 : 10,
          paddingTop: isMobileLandscape ? 3 : 5,
          paddingBottom: isMobileLandscape ? 3 : 5,
          background: red75,
        }}
      >
        {name}
        <Center style={{ marginRight: 0, paddingRight: 0 }}>
          <Ellipse
            width={12}
            height={12}
            landscapeWidth={8}
            landscapeHeight={8}
          />
        </Center>
      </RedBackgroundText>
    </Button>
  );
};

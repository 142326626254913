import React from "react";
import { RedBackgroundText, Center } from "./App";
import { Ellipse } from "./ellipse";

export const CurrentlyPlaying = ({ title }: { title: string }) => (
  <RedBackgroundText>
    <span>{title}</span>
    <Center style={{ marginRight: 0, paddingRight: 0 }}>
      <Ellipse />
    </Center>
  </RedBackgroundText>
);

import React from "react";
import { mobileLandscapeQuery } from "./App";
import styled from "styled-components";

const Image = styled.img<{
  width: number;
  height: number;
  landscapeWidth: number;
  landscapeHeight: number;
}>(({ width, height, landscapeWidth, landscapeHeight }) => ({
  width: width,
  height: height,
  [mobileLandscapeQuery]: {
    width: landscapeWidth,
    height: landscapeHeight,
  },
}));

export const Ellipse = ({
  width = 20,
  height = 20,
  landscapeWidth = 14,
  landscapeHeight = 14,
}: {
  width?: number;
  height?: number;
  landscapeWidth?: number;
  landscapeHeight?: number;
}) => (
  <Image
    src={process.env.PUBLIC_URL + "/assets/ellipse.svg"}
    alt="Live icon"
    {...{ width, height, landscapeWidth, landscapeHeight }}
  />
);
